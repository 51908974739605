<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-row>
      <label for="">机构</label>
      <el-select v-model="searchForm.mechanism_id" filterable remote :remote-method="getMechanism" clearable
        @focus.once="getMechanism">
        <el-option v-for="(item, index) in mechanism_list" :key="index" :label="item.mechanism_name" :value="item.id">
        </el-option>
      </el-select>
      <el-radio-group :disabled='!searchForm.mechanism_id' v-model="type" @change="changeAppointType">
        <el-radio label="appointment">预约课程</el-radio>
        <el-radio label="fixed_scheduling">固定课程</el-radio>
        <el-radio label="场馆">场馆</el-radio>
      </el-radio-group>
      <el-date-picker
      v-if="type == '场馆'"
      v-model="searchTime"
      type="daterange"
      value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
      <el-select v-model="searchForm.server_id" clearable remote :remote-method="searchCourse" filterable
        @focus='searchCourse' v-if="type && type != '场馆'">
        <el-option v-for="(item) in search_course_list" :key="item.id" :label="item.title" :value="item.id">
        </el-option>
      </el-select>
      <el-date-picker v-model="value" @change='chooseClick' type="month"
        v-if="searchForm.master_set_price_id && type!= 'fixed_scheduling'">
      </el-date-picker>
      <el-select v-model="searchForm.week_num" :disabled='!value'
        v-if="searchForm.master_set_price_id && type!= 'fixed_scheduling'">
        <el-option :label="'第'+i+'周'" :value="i"
          v-for="i,index in Math.ceil(getDateCount(this.searchForm.months_num)/7)" :key="index">
        </el-option>
      </el-select>
      <el-button type="primary" class="m-l20" @click="search()">查询</el-button>
    </el-row>
    <el-row v-if="type == '场馆'" class="m-t10">
      <label for="">场馆</label>
      <el-select v-model="searchForm.venue_id" clearable remote :remote-method="getPlace" filterable
       @focus="getPlace">
        <el-option v-for="(item, index) in place_list" :key="index" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <label for="" >服务</label>
      <el-select  v-model="searchForm.server_id" clearable remote
        :remote-method="getService" filterable @focus="getService" @change="changeServer">
        <el-option v-for="(item, index) in service_list" :key="index" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <label for="" >设备</label>
      <el-radio-group @change="changeDevice" v-model="device_id" v-for="(dev,index) in deviceList" :key="index">
        <el-radio :label="dev.id" >{{ dev.name }}</el-radio>
      </el-radio-group>
    </el-row>
    <el-row v-if="type == '场馆'">
      <calendar :list="venueTableList" :disable="true"/>

    </el-row>
    <el-row v-else>
      <div class="class-table">
        <div class="table-wrapper">
          <div class="tabel-container">
            <table>
              <thead>
                <tr>
                  <th>上课时间</th>
                  <th v-for="i,index in (7)" :key="index">{{weeks[i-1]}}</th>
                </tr>
              </thead>
              <tbody v-if="classTableData.length>0">
                <tr v-for="item,index in timeList" :key="index">
                  <th>
                    <div>{{item}}</div>
                  </th>
                  <th v-for="i in 7" :key="i">
                      <div v-for="cor in classTableData" :key="cor.id">
                          <div v-if='cor.weekday == i && cor.time_range == item'>
                            <div>{{cor.title}}</div>
                            <!-- <div>{{cor.master_id || '暂无教师'}}</div> -->
                            <el-button size="mini" @click="deleteItem(cor)" v-if="type=='appointment'">删除</el-button>
                        </div>
                      </div>
                  </th>
                </tr>
              </tbody>
            </table>
            <div v-if="classTableData.length==0" class="tips">
                <div v-if="searchForm.mechanism_id">
                  暂未排课
                </div>
                <div v-else>
                  请在上方输入要查看的机构
                </div>
            </div>
          </div>
        </div>
      </div>
    </el-row>

  </div>
</template>

<script>
import calendar from "../../components/calendar.vue";

export default {
  components:{
    calendar
  },
  data() {
    return {
      // 标题
      nav: { firstNav: '课程管理中心', secondNav: '课程表' },

      // 数据列表
      classTableData: [],
      mechanism_list: [], // 机构列表
      course_list: [], // 预约课程列表
      service_list: [], // 服务列表
      place_list: [], // 场馆列表
      search_course_list: [],
      weeks: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      // dialog

      // search
      searchForm: {
        mechanism_id: '',
        type: "",
        master_set_price_id: null,
        binding_venues_id: null,
        week_num: 1,
        months_num: new Date().getMonth() + 1,
        appointment_type: null,
      },
      searchTime:[],
      // 场馆服务

      value: new Date(),

      type: 'appointment',
      currentPage: 1,
      week_day: [],
      month: new Date().getMonth() +1,
      // commodity_type: '正式课',
      timeList:[],
      week_num:1,
      device_id:'',
      deviceList:[],
      venueTableList:[]
    }
  },
  computed: {},
  mounted() {
    // this.Getpaydetail();
    let day= new Date().getDate()
    console.log(day);
    this.week_num=Math.ceil(day/7)

  },
  methods: {
    changeServer(){
      let url = "/user/venue/device/getByServerId";
      this.$axios
        .get(url, {
          params: {
            server_id: this.searchForm.server_id,
          },
        })
        .then((res) => {
          this.deviceList = res.data.data;
        });
    },
    getlist(){
      if(this.type == 'appointment'){
        this.getApplist()
      }else if(this.type == 'fixed_scheduling'){
        this.getFixed()
      }else if(this.type == '场馆'){
        this.getVenueTable()
      }
    },
    // 获取预约课表
    getApplist() {
      let url = '/user/mechanismSchedule/query'
      const   params = {
          status: 2,
          // is_template: 0,
          master_set_price_id: this.searchForm.master_set_price_id,
          week_num: this.week_num,
          mechanism_id: this.searchForm.mechanism_id,
          months_num: this.month,
          type: "mechanism_offline",
        }
      this.$axios.get(url, { params }).then((res) => {
            let arr = res.data.data
          const range = new Set()
          this.classTableData = arr
          if(arr.length>0){
              arr.forEach(item=>{
                  let time_range=item.start_time + "-"+item.end_time
                  range.add(time_range)
                  item.time_range=time_range
                  item.title = item.name
                  item.weekday = item.week_day
              })
         
              this.timeList=[...range].sort()
          }
      })
    },
    //机构课程表，固定排课
    getFixed(){   
      var now = new Date();
      var nowDayOfWeek = now.getDay() == 0 ? 6 : (now.getDay()-1);
      var startTime = this.getDateStr(new Date(now.getTime() - nowDayOfWeek * 24 * 60 * 60 * 1000)) + ' 00:00:00';
      var endTime =  this.getDateStr(new Date(now.getTime() + (6-nowDayOfWeek) * 24 * 60 * 60 * 1000)) + ' 23:59:59';

      let url ="/user/masterAppointment/queryMechanismOfflineSchedule"
      const  params = {
         mechanism_id:this.searchForm.mechanism_id,
         start_time:startTime,
         end_time:endTime,
         offset:8,
         pageSize:20,
         currentPage:1,
         status:1
        }

        this.$axios({
          url,
          params
        }).then(res=>{
          let that =this
          let arr = res.data.data
          const range = new Set()
          this.classTableData = arr
          if(arr.length>0){

            arr = arr.filter(item=> {
              
              console.log(item.create_type ,that.type);
              return item.create_type == that.type})

            console.log(11,arr);
              arr.forEach(item=>{
                  let time_range=item.start_time.split(' ')[1] + "-"+item.end_time.split(' ')[1]
                  range.add(time_range)
                  item.time_range=time_range
                  let w = new Date(item.start_time).getDay()
                  item.weekday=w == 0 ? 7 : w
              })
         
              this.timeList=[...range].sort()
          }
        })
    },
    // 场馆课表
    getVenueTable(){
      if (!this.searchForm.server_id) {
        this.$message("请选择服务");
        return;
      }
      if (this.device_id == '') {
        this.$message("请选择设备");
        return;
      }
      let url = "/user/server/schedule/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 7,
            currentPage: 1,
            server_id: this.searchForm.server_id,
            device_id: this.device_id,
            date_start_time : this.searchTime[0] || null,
            date_end_time : this.searchTime[1] || null
          },
        })
        .then((res) => {
          let data = res.data.data.rows || [];
          let list = [];
          let dataObj = {}
          data.forEach((item) => {
            let json = JSON.parse(item.schedule_json);
            json.forEach((c) => {
              c.week_day = item.week_day;
              dataObj[item.week_day + c.start_time] = c
            });
          });
      for(let k in dataObj){
        list.push(dataObj[k])
      }
          this.venueTableList = list;
        });
    },
     getDateStr(now) {
			var year = now.getFullYear(); // 年
			var month = now.getMonth() + 1; // 月
			var day = now.getDate(); // 日
			if(day < 10) {
				day = '0' + day;
			}
			
			if(month < 10) {
				month = '0' + month;
			}		
			return year + "-" + month + "-" + day;
		},
    // 搜索
    search() {
      this.course_list=[]
      this.getlist()
    },
    chooseClick(e) {
      console.log(e)
      if (e) {
        this.searchForm.months_num = e.getMonth() + 1
        // this.date = e.getDate()
        this.searchForm.week_num = 1
      } else {
        this.searchForm.months_num = null
        this.searchForm.week_num = 1
        this.month = null
      }
      // console.log(this.month);
    },
    // 查询机构
    getMechanism(query) {
      let url = '/user/mastermechanism/queryByMessage'
      if (typeof query == 'string') {
        this.$axios
          .get(url, {
            params: {
              type: 'teach_paypal',
              status: 2,
              pageSize: 10,
              currentPage: this.currentPage,
              categories: '体育运动',
              mechanism_name: query,
            },
          })
          .then((res) => {
            // console.log(res.data);
            this.mechanism_list = res.data.data.rows
          })
          .catch((rej) => {
            this.is_loading = false
          })
      }
    },
    // 切换查询课表/课程
    changeAppointType(v) {
      this.course_list = []
      this.place_list = []
      this.searchForm.master_set_price_id = null
      this.searchForm.week_num = 1
      this.searchForm.months_num = new Date().getMonth() + 1
      this.searchForm.binding_venues_id = null
      switch (v) {
        case 'appointment':
          this.searchForm.type = 'mechanism_offline'
          this.searchForm.appointment_type = v
          this.getApplist()
          break
        case 'fixed_scheduling':
          this.searchForm.type = 'mechanism_offline'
          this.searchForm.appointment_type = v
          this.searchForm.months_num = null
          this.searchForm.week_num = null
          this.getFixed()
          break
        case '场馆':
          this.searchForm.type = v
          break
      }
    },
    changeDevice(v){
     
      this.device_id = v
    },
    getDateCount(month) {
      if (month) {
        return new Date(new Date().getFullYear(), month, 0).getDate()
      } else {
        return null
      }
    },

    deleteItem(item) {
      let url = '/user/mechanismSchedule/update'
      this.$axios
        .post(url, {
          id: item.id,
          status: 5,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getlist()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message,
            })
          }
        })
    },

    getPlace(query) {
      let url = '/user/mechanism/venue/queryManagerListPage'
      // }
      this.$axios
        .get(url, {
          params: {
            status: 0,
            pageSize: 10,
            currentPage: 1,
            mechanism_id: this.searchForm.mechanism_id,
            name: typeof query == 'string' ? query : null,
          },
        })
        .then((res) => {
          console.log(res)
          this.place_list = res.data.data.rows
        })
    },
    searchCourse(query) {
      let url = '/user/venue/server/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            status: 1,
            pageSize: 10,
            currentPage: 1,
            mechanism_id: this.searchForm.mechanism_id,
            name: typeof query == 'string' ? query : null,
          },
        })
        .then((res) => {
          this.search_course_list = res.data.data.rows
        })
      // }
    },

    getService(query) {
      let url = "/user/venue/server/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 1,
            name: typeof query == "string" ? query : undefined,
            pageSize: 10,
            currentPage: 1,
            mechanism_id:this.searchForm.mechanism_id,
            server_id : this.searchForm.server_id,
            type: 1,
          },
        })
        .then((res) => {
          this.service_list = res.data.data.rows;
        });
    },
  },
}
</script>

<style lang='less' scoped>
.class-table {
  .table-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .tabel-container {
    margin: 7px;

    table {
      table-layout: fixed;
      width: 100%;

      thead {
        background-color: #67a1ff;
        th {
          color: #fff;
          line-height: 17px;
          font-weight: normal;
        }
      }
      tbody {
        background-color: #eaf2ff;
        td {
          color: #677998;
          line-height: 12px;
        }
      }
      th,
      td {
        width: 60px;
        height:40px;
        padding: 12px 2px;
        font-size: 12px;
        text-align: center;
      }

      tr td:first-child {
        color: #333;
        .period {
          font-size: 8px;
        }
      }
    }
  }
}
.tips{
  font-size: 16px;
  text-align: center; 
  line-height: 3;
}
label{
  margin: 0 10px;
}
</style>
