<template>
  <div class="class-table flex-col al-c">
    <div class="table-wrapper">
      <div class="tabel-container">
        <table border="1" class="c-table">
          <thead>
            <tr>
              <th>时间</th>
              <th v-for="(day, index) in weeks" :key="index"><span>{{ day }}</span><span v-if="index === today -1">(今天)</span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in times" :key="i">
              <td>{{ getTimeRange(i) }}</td>
              <td
                v-for="d in [1, 2, 3, 4, 5, 6, 7]"
                :key="d"
                @click="changeTimeStatus(d, i)"
                class="time"
                :class="getColor(d, i)"
              >
                <span v-if="getColor(d, i) == 'green'">闲时</span>
                <span v-else-if="getColor(d, i) == 'red'">高峰</span>
                <span v-else>未开放</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="choose flex jc-e">
      <span class="green squre" @click="changeStatus(1)">闲时</span>
      <span class="red squre" @click="changeStatus(2)">高峰</span>
      <span class="squre" @click="changeStatus(0)">未开放</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
    },
    disable: {
      type: Boolean,
    },
  },
  data() {
    return {
      weeks:  ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      start_time: "06:00:00",
      end_time: "24:00:00",
      schedule: [],
      changedTime: [],
      status: 0,
      date_count: 7,
      checkList: [],
      times: [],
      today:''
    };
  },
  created() {
  this.today= new Date().getDay() === 0 ? 7 : new Date().getDay()
    for (let i = 7; i <= 22; i++) {
      this.times.push(i);
    }
  },
  watch: {
    list: {
      handler: function (newV, oldV) {
        this.updateData(newV);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getColor(d, t) {
      let obj = {};
      for (let i = 0; i < this.checkList.length; i++) {
        let time =
          this.checkList[i].start_time + "-" + this.checkList[i].end_time ==
          this.getTimeRange(t);
        if (this.checkList[i].week_day == d && time) {
          obj = this.checkList[i];
          break;
        }
      }
 
      if (obj.on_open) {
        return "";
      } else if (obj.is_big && obj.start_time) {
        return "red";
      } else if (!obj.is_big && obj.start_time) {
        return "green";
      } else {
        return "";
      }
    },
    updateData(data) {
      this.checkList = [];
      data.forEach((item) => {
        this.checkList.push({
          week_day: item.week_day,
          start_time: item.start_time,
          end_time: item.end_time,
          is_big: item.is_big,
          sold_out:item.sold_out || 0,
          on_open: false,
        });
      });
    },

    getTimeRange(v) {
      let start_time = v >= 10 ? v : "0" + v;
      let end_time = v + 1 >= 10 ? v + 1 : "0" + (v + 1);
      return start_time + ":00-" + end_time + ":00";
    },
    changeStatus(v) {
      this.status = v;
    },

    changeTimeStatus(d, t) {
      if(this.disable === true){
          return
      }
      let start_time = t >= 10 ? t : "0" + t;
      let end_time = t + 1 >= 10 ? t + 1 : "0" + (t + 1);
      let obj = {};
      for (let i = 0; i < this.checkList.length; i++) {
        let time =
          this.checkList[i].start_time + "-" + this.checkList[i].end_time ==
          this.getTimeRange(t);
        if (this.checkList[i].week_day === d && time) {
          obj = this.checkList[i];
          break;
        }
      }
      if (this.status === 0) {
        if (obj.week_day) {
          obj.on_open = true;
        }
      } else if (this.status === 1) {
        if (obj.week_day) {
          obj.is_big = false;
          obj.on_open = false;
        } else {
          this.checkList.push({
            week_day: d,
            start_time: start_time + ":00",
            end_time: end_time + ":00",
            is_big: false,
            on_open: false,
            sold_out:0
          });
        }
      } else if (this.status === 2) {
        if (obj.week_day) {
          obj.is_big = true;
          obj.on_open = false;
        } else {
          this.checkList.push({
            week_day: d,
            start_time: start_time + ":00",
            end_time: end_time + ":00",
            is_big: true,
            on_open: false,
            sold_out:0
          });
        }
      }

      this.$emit("postSchedule", this.checkList);
    },
  },
};
</script>

<style scoped lang='less'>
.class-table {
  display: flex;
  flex-wrap: wrap;
  .table-wrapper {
    height: 100%;
    overflow: auto;
  }
  .tabel-container {
    margin: 7px;

    table {
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;
      thead {
        background-color: #67a1ff;
        th {
          color: #fff;
          font-weight: normal;
        }
      }
      tbody {
        background-color: #eaf2ff;
        td {
          color: #677998;
        }
      }
      th,
      td {
        padding: 4px 2px;
        font-size: 12px;
        text-align: center;
        height: 2.5rem;
        width: 3.5rem;
      }

      tr td:first-child {
        color: #333;
        width: 100px;
        .period {
          font-size: 8px;
        }
      }
    }
  }
}
table,
tr,
td {
  user-select: none;
}
.choose {
  width: 100%;
}
.green {
  background-color: greenyellow;
}
.red {
  background-color: orangered;
  color: white !important;
}
.time,
.squre {
  cursor: pointer;
}
.squre {
  display: block;
  width: 5rem;
  height: 3rem;
  border: 1px solid red;
  text-align: center;
  line-height: 3rem;
}
</style>